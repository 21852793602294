<template>
  <div class="shopMember">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/goods' }">商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品统计</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="shop-screen">
      <el-button
          type="primary"
          size="small"
          style="background: #4781d9; border: 0"
          class="btn-item"
          @click="chainGoodExport"
        >
          <span>导出</span>
          <i class="iconfont icon-daochu" style="font-size:12px"></i>
        </el-button>
      <el-form
        ref="form"
        :model="goodsForm"
        label-width="80px"
        size="mini"
        class="shopScreenForm"
      >
        <el-form-item label="门店名称">
          <el-input
            v-model="goodsForm.shopName"
            style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input
            v-model="goodsForm.goodsName"
            style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item label="日期" style="margin-left: 50px">
          <el-date-picker
            v-model="goodsForm.timer"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="商品是否上架" label-width="200px" prop="is_shelf">
          <el-select
            v-model="goodsForm.is_shelf"
            placeholder="请选择"
            class="ipt"
          >
            <el-option
              v-for="(item, index) in this.l_cate"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            style="background: #cd0550; border: none"
            @click="search"
            >搜索</el-button
          >
          <el-button @click="emptySearch">重置</el-button>
        </el-form-item>
      </el-form>
      
    </div>
    <div class="data-table">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          type="index"
          :header-cell-style="{ background: '#CD0550', color: '#fff' }"
        >
          <el-table-column prop="id" label="ID"> </el-table-column>
          <el-table-column prop="shop.name" label="门店名称"> </el-table-column>
          <el-table-column prop="good.good_name" label="商品名称"> </el-table-column>
          <el-table-column prop="day" label="日期"></el-table-column>
          <el-table-column prop="platform_service_fee" label="取袋时间">
              <template slot-scope="scope">
                  {{scope.row.pickup_time_start+"-"+scope.row.pickup_time_end}}
              </template>
          </el-table-column>
          <el-table-column prop="good_quantity" label="上架数量"> </el-table-column>
          <el-table-column prop="sell_quantity" label="售出数量"> </el-table-column>
          <el-table-column prop="created_at" label="最后上架时间"> </el-table-column>
        </el-table>
        <div class="page-num">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="prev,pager,next, total,sizes,jumper"
            :total="total"
            background
          >
          </el-pagination>
        </div>
      </div>
  </div>
</template>

<script>

import { imgUrl, httpUrl } from "@/utils/http.js"
import {GoodsStatistics_api} from "@/api/goods.js"
import local from "@/utils/local.js"
import axios from "axios"
export default {
  data() {
    return {
      imgUrl:imgUrl,
      goodsForm: {
        shopName: "",
        goodsName:'',
        timer:'',
        is_shelf:''
      },
      currentPage: 1,
      pageSizes: [10, 20,50],
      pageSize: 10,
      total: 0,
      tableData:[],
      active:1,
      filename:'商品统计',
      l_cate: [
      { title: "未上架", id: 1 },
      { title: "已上架", id: 2 },
      ]
    }
  },
  methods: {
    //获取商品列表
    async getGoodsList(){
      let res=await GoodsStatistics_api({
        shop_name:this.goodsForm.shopName,
        good_name:this.goodsForm.goodsName,
        start_date:this.goodsForm.timer[0],
        end_date:this.goodsForm.timer[1],
        is_shelf:this.goodsForm.is_shelf,
        page:this.currentPage,
        limit:this.pageSize
      })
      this.tableData=res.data.data.list
      this.total=res.data.data.total_count
    },
    //重置
    emptySearch() {
      this.goodsForm = {
        shopName: "",
        goodsName:'',
        timer:''
      }
      this.currentPage=1
      this.getGoodsList()
    },
    //搜索
    search() {
      this.currentPage=1
      this.getGoodsList()
    },
    //改变条数
    handleSizeChange(e) {
      this.pageSize=e
      this.getGoodsList()
    },
     //切换页
    handleCurrentChange(e) {
      this.currentPage=e
      this.getGoodsList()
    },
    //去编辑
    operation(row) { 
      local.set("goodsInfo",row)
      this.$router.push('/goods/changeGoods')
    },
       //导出
    chainGoodExport() {
      const loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      axios
        .get(
          `${httpUrl}api/v1/good/statistics/export?shop_name=${this.goodsForm.shopName?this.goodsForm.shopName:''}&good_name=${this.goodsForm.goodsName}&start_date=${this.goodsForm.timer[0]?this.goodsForm.timer[0]:''}&end_date=${this.goodsForm.timer[1]?this.goodsForm.timer[1]:''}`,
          {
            responseType: "blob", //服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'，默认是'json'
          }
        )
        .then((res) => {
          if (!res) return;
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          }); // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename);
          } else {
            const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = this.filename; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
             loading.close()
          }
          // 这里也可以不创建a链接，直接window.open(href)也能下载
        })
        .catch((err) => {
          this.$message.error('导出失败,请重试!')
          loading.close()
        })
    }
  },
  created(){
     this.goodsForm.shopName=local.get("shopName1")
    this.getGoodsList()
  },
  destroyed(){
    local.remove("shopName1")
  }
}
</script>

<style lang="less" scoped>
.shopMember {
  width: 100%;
  height: 100%;
  background: #eee;
  box-sizing: border-box;
  padding: 30px;
}
.shop-screen {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
.shopScreenForm {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin-top: 20px;
  flex-wrap: wrap;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #cd0550 !important;
}
/deep/.el-pager li {
  border: 1px solid #bfbfbf;
  background: #fff !important;
  box-sizing: border-box !important;
}
/deep/.el-pagination.is-background .btn-next {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-pagination.is-background .btn-prev {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-table {
  overflow: visible;
}
/deep/td {
  text-align: center !important;
}
/deep/.cell {
  text-align: center !important;
  overflow: visible;
}
/deep/.el-table__body-wrapper {
  overflow: visible;
}
/deep/.is-active {
  color: #cd0550;
}
/deep/.el-tabs__active-bar {
  background: #cd0550;
}
.data-table {
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.page-num {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
.operation {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: -90px;
  z-index: 99;
  background: #fff;
  p {
    width: 85px;
    height: 40px;
    // background: rgba(246, 219, 229, 0.2);
    border-radius: 0px;
    text-align: center;
    line-height: 40px;
    border: 1px #eee solid;
    font-size: 14px;
    font-weight: bold;
    color: #999999;
  }
}
.active {
  background: #cd0550 !important;
  color: #fff !important;
}
</style>